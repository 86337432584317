interface NavigationRules {
    pathCondition: boolean;
    condition: boolean;
    redirect: string;
}

export default defineNuxtRouteMiddleware(async (to) => {
    const { user } = storeToRefs(userSessionStore());
    const nuxtApp = useNuxtApp();
    const { credentials } = storeToRefs(useJanusStore());
    const runtimeConfig = useRuntimeConfig();
    const { data: subscriptionData, error } = await useFetch('/api/auth/subscription', {
        key: `subscription-status-${user.value?.user_metadata.organization_id}`,
        headers: useRequestHeaders(['cookie']),
        getCachedData: (key) => nuxtApp.payload.data[key],
    });
    if (!credentials.value?.user_id) {
        const { data, status } = await useFetch('/api/pbx/settings', {
            key: `pbx-settings-${user.value?.id}`,
            headers: useRequestHeaders(['cookie']),
            getCachedData: (key) => nuxtApp.payload.data[key],
        });

        if (status.value == 'success' && !data.value)
            throw createError({ status: 400, statusMessage: 'Failed to fetch PBX settings' });

        credentials.value = {
            user_id: data.value?.user_id as string,
            server: runtimeConfig.public.OFON_SERVER as string,
            proxy: `sip:${data.value?.realm}:5060`,
            realm: data.value?.realm as string,
            username: `sip:${data.value?.username}@${data.value?.realm}`,
            secret: data.value?.secret as string,
            display_name: data.value?.display_name as string,
        };

        if (error.value?.statusCode?.toString().startsWith('5')) return navigateTo('/server-error');
    }

    const currentPath = to.path;
    const organizationId = user.value?.user_metadata.organization_id;
    const subscriptionStatus = subscriptionData.value?.status;

    const rules: NavigationRules[] = [
        {
            pathCondition: currentPath !== '/dashboard/settings/organization/plans-payments',
            condition: subscriptionStatus === 'inactive',
            redirect: '/dashboard/settings/organization/plans-payments',
        },
        {
            pathCondition: currentPath == '/dashboard/idb2b-resources/add',
            condition: organizationId !== 2,
            redirect: '/dashboard/resources/idb2b-resources',
        },
    ];

    for (const rule of rules) {
        if (rule.pathCondition && rule.condition) {
            return navigateTo(rule.redirect);
        }
    }

    if (currentPath.startsWith('/dashboard/settings/connect/integration')) {
        if (!currentPath.endsWith('/integration')) {
            const integrationName = currentPath.split('/')[5];
            const isInstalled = ref(useIntegrationInstalled(integrationName));

            if (!isInstalled.value) {
                return navigateTo('/dashboard/settings/connect/integration');
            }
        }
    }
});
